import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
	StickyLeadBarState,
	_STATE_STICKY_LEAD_BAR_FEATURE_KEY,
} from './sticky-lead-bar.reducer';

export const getStickyLeadBarState = createFeatureSelector<StickyLeadBarState>(
	_STATE_STICKY_LEAD_BAR_FEATURE_KEY,
);

export const getStateEvents = createSelector(
	getStickyLeadBarState,
	(state: StickyLeadBarState) => state.events,
);
