import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
	FeaturedPostgraduate,
	FeaturedUndergraduate,
	Product,
	ProductEnum,
	SourceTrackingTypes,
} from '@uc/web/shared/data-models';
import {
	VisitWebsiteBtnComponent,
	BookEventBtnComponent,
	RequestBtnComponent,
	ProspectusBtnComponent,
} from '@web/shared/ui';

@Component({
	selector: 'uc-cta-buttons',
	standalone: true,
	templateUrl: './cta-buttons.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		VisitWebsiteBtnComponent,
		BookEventBtnComponent,
		RequestBtnComponent,
		ProspectusBtnComponent,
	],
})
export class CtaButtonsComponent {
	@Input({ required: true }) uniId!: number;
	@Input({ required: true }) uniName!: string;
	@Input({ required: true }) product!: Product | null;
	@Input({ required: true }) componentName!: string;
	@Input({ required: true }) externalUrl!: string;
	@Input({ required: true }) prospectusExternalUrl!: string | undefined;
	@Input({ required: true }) externalEventsUrl!: string | undefined;
	@Input({ required: true }) source!: string;
	@Input({ required: true }) sourceType: SourceTrackingTypes = '';
	@Input({ required: true }) isFeaturedPostgraduate!: FeaturedPostgraduate;
	@Input({ required: true }) isFeaturedUndergraduate!: FeaturedUndergraduate;
	@Input({ required: true }) ugSearchOrderPosition!: number | null;
	@Input({ required: true }) cleSearchOrderPosition!: number | null;
	@Input() privacyPolicyUrl = '';
	@Input() courseName = '';

	readonly productEnum = ProductEnum;
}
