import { Directive, ElementRef, HostListener, Input, inject } from '@angular/core';

@Directive({
	selector: '[scrollIntoView]',
	standalone: true,
})
export class ScrollIntoViewDirective {
	@Input() scrollThreshold = 670;

	private _el = inject(ElementRef);

	@HostListener('window:scroll', ['$event']) onScroll(event: any) {
		const scrollPosition = event.currentTarget?.pageYOffset;

		if (scrollPosition > this.scrollThreshold) {
			this._el.nativeElement?.classList?.add('show-sticky-lead-bar');
			this._el.nativeElement?.classList?.remove('sticky-lead-bar');
		} else {
			this._el.nativeElement?.classList?.add('sticky-lead-bar');
			this._el.nativeElement?.classList?.remove('show-sticky-lead-bar');
		}
	}
}
