import {
	ChangeDetectionStrategy,
	Component,
	HostListener,
	Input,
	OnDestroy,
	OnInit,
} from '@angular/core';

import { Store, select } from '@ngrx/store';
import {
	StickyLeadBarColorEnum,
	StickyLeadBarColorScheme,
	StickyLeadBarEvent,
	StickyLeadBarType,
	StickyLeadBarTypeEnum,
} from '../models/sticky-lead-bar.models';
import { getStateEvents } from '../data-access/+state/sticky-lead-bar.selectors';
import { Product, ProductEnum, ScreenWidth } from '@uc/web/shared/data-models';
import {
	BookEventBtnComponent,
	RequestBtnComponent,
	VisitWebsiteKeywordBtnComponent,
} from '@web/shared/ui';
import { ScrollIntoViewDirective } from '../directives/scroll-into-view.directive';
import { getRandomElementFromArray, isArrayEmpty, isObjectEmpty } from '@uc/utilities';
import { CourseGTMService } from '@uc/web/core';
import { BookEventDataManager } from '../utils/book-event-data-manager';
import { fetchEvents } from '../data-access/+state/sticky-lead-bar.actions';
import {
	StatementKeywordAndCategory,
	getPersonalStatementKeywordsAndCategory,
} from '@uc/web/shared/data-access';
import { KeywordsDataManager } from '../utils/keywords-data-manager';
import { Subscription, filter, tap } from 'rxjs';
import { ContentStickyLeadBarComponent } from '../components/content-sticky-lead-bar/content-sticky-lead-bar.component';
import { BreakpointObserver } from '@angular/cdk/layout';
import { CustomCookieService } from '@uc/shared/authentication';

@Component({
	selector: 'uc-sticky-lead-bar-renderer',
	standalone: true,
	templateUrl: './sticky-lead-bar-renderer.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		BookEventBtnComponent,
		RequestBtnComponent,
		ContentStickyLeadBarComponent,
		ScrollIntoViewDirective,
		VisitWebsiteKeywordBtnComponent,
	],
})
export class StickyLeadBarRendererComponent implements OnInit, OnDestroy {
	@Input({ required: true }) leadType: StickyLeadBarType =
		StickyLeadBarTypeEnum.BOOK_EVENT;
	@Input({ required: true }) colorScheme: StickyLeadBarColorScheme =
		StickyLeadBarColorEnum.ROSE;
	@Input() link = '';
	@Input() mobileDescription = '';
	@Input() desktopDescription = '';
	@Input() title = '';
	@Input() trackKeywordImpression = false;
	@Input() uniId!: number;
	@Input() uniName!: string;

	protected componentName = 'Sticky Lead Bar';
	protected product!: Product;
	protected scrollThreshold = 10;
	protected isMobile = true;
	protected eventType = '';
	protected eventId!: number;
	protected date = '';
	protected userAuthenticated = !!this._customCookieSrv.get('user_id');
	protected ugSearchOrderPosition!: number | null;
	private _impressionTracked = false;

	private _eventsSub!: Subscription;
	private _keywordsSub!: Subscription;

	constructor(
		private _store: Store,
		private _breakpointObserver: BreakpointObserver,
		private _customCookieSrv: CustomCookieService,
		private _courseGTMSrv: CourseGTMService,
	) {}

	@HostListener('window:scroll', ['$event']) triggerScroll = (event: any) => {
		const scrollPosition = event.currentTarget.pageYOffset;
		if (this.trackKeywordImpression) {
			if (scrollPosition > this.scrollThreshold && !this._impressionTracked) {
				this._onKeywordImpression();
			} else if (
				scrollPosition <= this.scrollThreshold &&
				this._impressionTracked
			) {
				this._impressionTracked = false;
			}
		}
	};

	ngOnInit(): void {
		this.isMobile = this._breakpointObserver.isMatched(
			`(max-width: ${ScreenWidth.LG - 1}px)`,
		);
		this._validateLink();

		if (this.leadType == StickyLeadBarTypeEnum.BOOK_EVENT) {
			this._store.dispatch(fetchEvents());
			this._eventsSub = this._store
				.pipe(
					select(getStateEvents),
					filter((data) => !isArrayEmpty(data)),
					tap((data) => {
						const event = getRandomElementFromArray(data!);
						this._handleBookEventData(event!);
					}),
				)
				.subscribe();
		} else if (this.leadType == StickyLeadBarTypeEnum.VISIT_WEBSITE) {
			this._keywordsSub = this._store
				.pipe(
					select(getPersonalStatementKeywordsAndCategory),
					filter((data) => !isObjectEmpty(data.keywords)),
					tap((data) => this._handleKeywordData(data)),
				)
				.subscribe();
		}
	}

	ngOnDestroy(): void {
		this._eventsSub?.unsubscribe();
		this._keywordsSub?.unsubscribe();
	}

	private _handleBookEventData(data: StickyLeadBarEvent) {
		if (isObjectEmpty(data)) return;

		const event = new BookEventDataManager();
		event.getData(data);
		this.link = event.link;
		this.title = event.title;
		this.uniId = event.uniId;
		this.uniName = event.uniName;
		this.product = event.product;
		this.eventType = event.eventType;
		this.eventId = event.eventId;
		this.date = event.date;
		this.ugSearchOrderPosition = event.ugSearchOrderPosition;
		this.desktopDescription = event.desktopDescription;
		this.mobileDescription = event.mobileDescription;
		this.leadType = StickyLeadBarTypeEnum.BOOK_EVENT;
	}

	private _handleKeywordData(keywords: StatementKeywordAndCategory) {
		if (isObjectEmpty(keywords?.keywords)) return;

		const data = new KeywordsDataManager();
		data.getData(keywords);
		this.link = data.link;
		this.title = data.title;
		this.uniId = data.uniId;
		this.uniName = data.uniName;
		this.product = ProductEnum.Undergraduate;
		this.desktopDescription = data.desktopDescription;
		this.mobileDescription = data.mobileDescription;
		this.leadType = StickyLeadBarTypeEnum.VISIT_WEBSITE;
	}

	private _validateLink() {
		if (this.leadType == StickyLeadBarTypeEnum.REQUEST_INFO && !this.link) {
			console.error('Attribute "link" is required');
		}
	}

	//GTM Event: track impression if there's a uniId (only for keywords)
	private _onKeywordImpression() {
		if (this.uniId) {
			this._courseGTMSrv.keywordImpression(
				this.componentName,
				this.uniId,
				this.uniName,
				this.userAuthenticated,
				ProductEnum.Undergraduate,
			);
			this._impressionTracked = true;
		}
	}
}
