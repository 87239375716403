import { Action, createReducer, on } from '@ngrx/store';
import { loadStickyLeadBarFailure, updateEvents } from './sticky-lead-bar.actions';
import { StickyLeadBarEvent } from '../../models/sticky-lead-bar.models';

export const _STATE_STICKY_LEAD_BAR_FEATURE_KEY = 'stateStickyLeadBar';

export interface StickyLeadBarState {
	events: StickyLeadBarEvent[] | null;
	error: string | null;
}

export const initialState: StickyLeadBarState = {
	events: null,
	error: null,
};

const stateStickyLeadBarReducer = createReducer(
	initialState,
	on(updateEvents, (state, { events }) => ({
		...state,
		events: events,
		error: null,
	})),

	on(loadStickyLeadBarFailure, (state, error) => ({
		...state,
		error: error,
	})),
);

export function stickyLeadBarReducer(
	state: StickyLeadBarState | undefined,
	action: Action,
) {
	return stateStickyLeadBarReducer(state, action);
}
