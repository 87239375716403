import { Product, ProductEnum } from '@uc/web/shared/data-models';
import { DatePipe } from '@angular/common';
import { BaseDataManager, StickyLeadBarEvent } from '../models/sticky-lead-bar.models';
import { OrdinalPipe, UniPipe, capitaliseFirstLetter } from '@uc/utilities';

export class BookEventDataManager implements BaseDataManager {
	link = '';
	title = '';
	uniId!: number;
	uniName = '';
	product: Product = ProductEnum.Undergraduate;
	mobileDescription = '';
	desktopDescription = '';
	eventType = '';
	eventId!: number;
	date = '';
	ugSearchOrderPosition: number | null = null;

	private _datePipe = new DatePipe('en-GB');
	private _ordinal = new OrdinalPipe();
	private _uniPipe = new UniPipe();

	getData(event: StickyLeadBarEvent) {
		if (event) {
			const product = event?.product.toLowerCase() as Product;
			const day = Number(this._datePipe.transform(event.date, 'dd'));
			const ordinal = this._ordinal.transform(day ?? 0);
			const date = this._datePipe.transform(event.date, 'MMMM YYYY');
			const month = this._datePipe.transform(event.date, 'MMMM');
			const year = this._datePipe.transform(event.date, 'YYYY');
			const formattedProduct = product?.charAt(0).toUpperCase() + product?.slice(1);
			const eventType = this._transformText(event.type);

			this.eventType = event.type;
			this.eventId = event.id;
			this.date = event.date;
			this.link = event.external_url;
			this.title = `${formattedProduct} ${eventType} at ${event.university.name}`;
			this.uniId = event.university.id;
			this.ugSearchOrderPosition = event.university.order;
			this.uniName = event.university.name;
			this.product = product;
			this.mobileDescription = `<span>${formattedProduct} ${capitaliseFirstLetter(
				eventType,
			)} at ${this._uniPipe.transform(
				event.university.name,
			)} on <span class="text-uc-red-300 font-bold">${month} ${ordinal} ${year}</span>!</span>`;
			this.desktopDescription = `<span>Find out everything ${event.university.name} has to offer at their ${product} ${eventType}! <br> Join them on the <span class="text-gray-600 font-bold">${ordinal} of ${date}</span> to learn more.</span>`;
		}
	}

	private _transformText(text: string): string {
		const words = text.split('_');
		const transformedWords = words.map((word) => word.toLowerCase());
		const transformedText = transformedWords.join(' ');
		return transformedText;
	}
}
