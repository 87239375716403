import { Product, ProductEnum } from '@uc/web/shared/data-models';
import { BaseDataManager } from '../models/sticky-lead-bar.models';
import {
	StatementKeyword,
	StatementKeywordAndCategory,
} from '@uc/web/shared/data-access';
import { capitalise, isObjectEmpty, replaceDashWithSpace } from '@uc/utilities';
type StickyBannerContentType = 'title' | 'desc';

export class KeywordsDataManager implements BaseDataManager {
	link = '';
	title = '';
	uniId!: number;
	uniName!: string;
	product: Product = ProductEnum.Undergraduate;
	mobileDescription = '';
	desktopDescription = '';

	private _defaultText = {
		title: 'Want to ask a university about your personal statement?',
		description: 'Get your questions answered now by sending them an enquiry',
	};

	getData(data: StatementKeywordAndCategory) {
		if (data?.keywords) {
			const keyword = data.keywords[0];
			this.link = keyword.external_url;
			this.title = this._getCopy(keyword, 'title');
			this.uniId = keyword.university.id;
			this.uniName = keyword.university.name;
			this.desktopDescription = this._getCopy(keyword, 'desc');
			this.mobileDescription = this._getMobileCopy(
				data.category!,
				keyword.university.name,
			);
		}
	}

	private _getCopy(keyword: StatementKeyword, type: StickyBannerContentType): string {
		const defaultText =
			type === 'title' ? this._defaultText.title : this._defaultText.description;
		const keywordContent = type === 'title' ? keyword.title : keyword.description;

		return isObjectEmpty(keyword) ? defaultText : keywordContent;
	}

	private _getMobileCopy(catName: string, uniName: string) {
		const formattedCatName = capitalise(replaceDashWithSpace(catName));
		return `Discover <span class="text-blue-200 font-bold">${formattedCatName}</span> at ${uniName}!`;
	}
}
