import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';
import { StickyLeadBarColorScheme } from '../models/sticky-lead-bar.models';

@Directive({
	selector: '[colorScheme]',
	standalone: true,
})
export class ColorSchemeDirective implements AfterViewInit {
	@Input({
		required: true,
		transform: (value: StickyLeadBarColorScheme) => value?.toLowerCase(),
	})
	colorScheme!: Lowercase<StickyLeadBarColorScheme>;
	@Input({ required: true }) classType!: 'background' | 'text';

	private readonly _classOptions = {
		background: {
			green: ['bg-uc-green-50', 'border-uc-green-200'],
			blue: ['bg-uc-blue-50', 'border-uc-blue-200'],
			rose: ['bg-uc-red-50', 'border-uc-red-200'],
		},
		text: {
			green: ['text-uc-green-900'],
			blue: ['text-uc-blue-900'],
			rose: ['text-uc-red-900'],
		},
	};

	constructor(private _el: ElementRef) {}

	ngAfterViewInit(): void {
		if (this.colorScheme && this.classType) {
			this._classOptions[this.classType][this.colorScheme]?.map((className) => {
				this._el.nativeElement?.classList?.add(className);
			});
		}
	}
}
