import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { GET_EVENTS } from './graphql-queries';
import { EMPTY, catchError, map } from 'rxjs';
import { Store } from '@ngrx/store';
import { StickyLeadBarState } from './+state/sticky-lead-bar.reducer';
import { loadStickyLeadBarFailure } from './+state/sticky-lead-bar.actions';
import { StickyLeadBarEvent } from '../models/sticky-lead-bar.models';

@Injectable({
	providedIn: 'root',
})
export class StickyLeadBarApiService {
	constructor(
		private _store: Store<StickyLeadBarState>,
		private _apollo: Apollo,
	) {}

	getEvents() {
		return this._apollo
			.query<{
				events: {
					data: StickyLeadBarEvent[];
					recommended_events: StickyLeadBarEvent[];
				};
			}>({
				query: GET_EVENTS,
				variables: { limit: 20 },
			})
			.pipe(
				catchError((error) => {
					console.error(error);
					this._store.dispatch(loadStickyLeadBarFailure(error));
					return EMPTY;
				}),
				map(({ data }) => {
					if (data?.events?.recommended_events?.length > 0) {
						return data.events.recommended_events;
					} else {
						return data?.events?.data;
					}
				}),
			);
	}
}
