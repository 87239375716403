import {
	AfterViewChecked,
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	Inject,
	Input,
	OnInit,
	PLATFORM_ID,
	ViewChild,
} from '@angular/core';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { ColorSchemeDirective } from '../../directives/color-scheme.directive';
import {
	FakeBtnStyleEnum,
	FakeBtnTextEnum,
	StickyLeadBarColorScheme,
	StickyLeadBarType,
	StickyLeadBarTypeEnum,
} from '../../models/sticky-lead-bar.models';
import {
	BookEvent,
	BookEventGTMService,
	CourseGTMService,
	RequestInfoGTMService,
	ProductHandlerService,
} from '@uc/web/core';
import { Product, ProductEnum } from '@uc/web/shared/data-models';
import { EntitiesPipe } from '@uc/utilities';
import { ArrowRightSvgComponent } from '@uc/shared/svg';
import { BookEventBtnApiService } from '@web/shared/ui';

@Component({
	selector: 'uc-content-sticky-lead-bar',
	standalone: true,
	templateUrl: './content-sticky-lead-bar.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [EntitiesPipe, CommonModule, ColorSchemeDirective, ArrowRightSvgComponent],
})
export class ContentStickyLeadBarComponent implements OnInit, AfterViewChecked {
	@Input({ required: true }) colorScheme!: StickyLeadBarColorScheme;
	@Input({ required: true }) leadType: StickyLeadBarType =
		StickyLeadBarTypeEnum.BOOK_EVENT;
	@Input({ required: true }) title = '';
	@Input({ required: true }) description = '';
	@Input({ required: true }) link = '';
	@Input({ required: true }) componentName = '';
	@Input({ required: true }) uniId!: number;
	@Input({ required: true }) uniName!: string;
	@Input({ required: true }) isUser!: boolean;
	@Input({ required: true }) product!: Product;
	@Input({ required: true }) ugSearchOrderPosition!: number | null;
	@Input() eventType!: string;
	@Input() eventId!: number;
	@Input() date!: string;

	@ViewChild('fakeBtn') fakeBtn!: ElementRef<HTMLSpanElement>;

	protected isiOSChrome: boolean = true;
	protected buttonText = 'Book Event';

	constructor(
		@Inject(PLATFORM_ID) private _platformId: Object,
		private _bookEventGTMSrv: BookEventGTMService,
		private _courseGTMSrv: CourseGTMService,
		private _requestInfoGTMSrv: RequestInfoGTMService,
		private _bookEventBtnApiSrv: BookEventBtnApiService,
		private _productHandlerSrv: ProductHandlerService,
	) {}

	ngOnInit(): void {
		if (isPlatformBrowser(this._platformId)) {
			this.isiOSChrome = navigator.userAgent.indexOf('CriOS') >= 0;
		}
	}

	ngAfterViewChecked(): void {
		this._setFakeBtnTextAndStyle();
	}

	onClick() {
		if (this.leadType === StickyLeadBarTypeEnum.BOOK_EVENT) {
			this._bookEventClick();
		} else if (this.leadType === StickyLeadBarTypeEnum.VISIT_WEBSITE) {
			this._visitWebsiteClick();
		} else if (this.leadType === StickyLeadBarTypeEnum.REQUEST_INFO) {
			this._requestInfoClick();
		}
	}

	private _bookEventClick() {
		const args: BookEvent = {
			uniId: this.uniId,
			uniName: this.uniName,
			isUser: this.isUser,
			componentName: this.componentName,
			eventType: this.eventType,
			date: this.date,
			ugSearchOrderPosition: this.ugSearchOrderPosition,
		};
		this._bookEventGTMSrv.bookEventClick(args);
		this._bookEventBtnApiSrv.trackEventClicks(this.uniId, this.eventId, this.product);
	}

	private _visitWebsiteClick() {
		this._courseGTMSrv.keywordClick(
			this.componentName,
			this.uniId,
			this.uniName,
			this.isUser,
			this.product,
		);
	}

	private _requestInfoClick() {
		this._requestInfoGTMSrv.requestInfoClick(
			this.componentName,
			this.product === ProductEnum.Undergraduate || !this.product
				? this.ugSearchOrderPosition
				: null,
		);
		this._productHandlerSrv.setProduct(this.product ?? ProductEnum.Undergraduate);
	}

	private _setFakeBtnTextAndStyle() {
		this.fakeBtn.nativeElement.innerText = FakeBtnTextEnum[this.leadType];
		this.fakeBtn.nativeElement.classList.add(FakeBtnStyleEnum[this.leadType]);
	}
}
