import { createAction, props } from '@ngrx/store';
import { StickyLeadBarEvent } from '../../models/sticky-lead-bar.models';

export const fetchEvents = createAction('[AdviceState/API] Fetch Advice Events');

export const updateEvents = createAction(
	'[AdviceState] Update Events',
	props<{ events: StickyLeadBarEvent[] }>(),
);

export const loadStickyLeadBarFailure = createAction(
	'[AdviceState] Load Sticky Lead Bar Failure',
	props<any>(),
);
